import React, { useEffect, useState, useCallback } from "react";
import StandardContainerWithCustomAppBar from "../styled/generic/StandardContainerWithCustomAppBar";
import {
  Box,
  Typography,
  Button,
  Drawer,
  Grid,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardBackspace } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import ProjectPickerDropdown from "../styled/generic/ProjectPickerDropdown";
import OrgPickerDropdown from "../styled/generic/OrgPickerDropdown";
import DatePicker from "../styled/generic/DatePicker";
import dayjs from "dayjs";
import ProgressUpdatesDrawer from "./ProgressUpdatesDrawer";
import TaskUpdates from "./TaskUpdateDisplay";
import arrayToReducer from "../../helpers/arrayToReducer";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const EditCustomerReport = () => {
  const classes = useStyles();
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [defaultProjectId, setDefaultProjectId] = useState(null);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [report, setReport] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState({});
  const [selectedProgressUpdates, setSelectedProgressUpdates] = useState({});
  const [selectedDocs, setSelectedDocs] = useState({});
  const [refreshTaskUpdates, setRefreshTaskUpdates] = useState(0);
  const [isExternal, setIsExternal] = useState(false);
  const [showPublicDialog, setShowPublicDialog] = useState(false);

  const getReport = useCallback(async () => {
    try {
      const { data } = await Api.post("/site-report/get-by-id", {
        reportId: reportId,
      });
      console.log('datadata-', JSON.stringify(data));
      
      setReport(data);
      setTitle(data?.title);
      setDescription(data?.description);
      setSelectedProject(data?.project?._id);
      setSelectedOrganization(data?.organization?._id);
      setDefaultProjectId(data?.project?._id);
      setDefaultOrganizationId(data?.organization?._id);
      setStartDate(data?.startDate ? dayjs(data.startDate) : null);
      setEndDate(data?.endDate ? dayjs(data.endDate) : null);
      setIsExternal(data?.isExternal);

      // Initialize selectedTasks, selectedProgressUpdates, and selectedDocs based on the fetched report data
      const initialSelectedTasks = {};
      const initialSelectedProgressUpdates = {};
      const initialSelectedDocs = {};



      // eslint-disable-next-line no-unused-vars
      if (Array.isArray(data?.TaskReport)) {

        const { idArr: taskIds, newDict: taskDict} = arrayToReducer(data?.tasks)
        data.TaskReport.forEach((taskObject) => {
          const taskId = taskObject?.progressUpdate?.parent?._id;
          if (taskId) {
            initialSelectedTasks[taskId] = true;
            
            if (!initialSelectedProgressUpdates[taskId]) {
              initialSelectedProgressUpdates[taskId] = {};
            }
            initialSelectedProgressUpdates[taskId][taskObject.progressUpdate._id] = true;
  
            if (!initialSelectedDocs[taskId]) {
              initialSelectedDocs[taskId] = {};
            }
            initialSelectedDocs[taskId][taskObject.progressUpdate._id] = {};
            
            if (Array.isArray(taskObject.docs)) {
              taskObject.docs.forEach((doc) => {
                if (doc?._id) {
                  initialSelectedDocs[taskId][taskObject.progressUpdate._id][doc._id] = true;
                }
              });
            }
          }
        });
      }
      setSelectedTasks(initialSelectedTasks);
      setSelectedProgressUpdates(initialSelectedProgressUpdates);
      setSelectedDocs(initialSelectedDocs);

      console.log("Parsed dates:");
      console.log(
        "Start Date:",
        data?.startDate ? dayjs(data.startDate).format("YYYY-MM-DD") : null
      );
      console.log(
        "End Date:",
        data?.endDate ? dayjs(data.endDate).format("YYYY-MM-DD") : null
      );
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching report",
        },
      });
    }
  }, [reportId, dispatch]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  const saveExternalReport = async () => {
    try {
      const updatedData = {
        _id: reportId,
        updatedSiteReportData: {
          type: "Customer Report",
          isExternal: !isExternal,
        },
      };
      const { data } = await Api.post("/site-report/update", updatedData);
    } catch (err) {
      console.error(err);
    }
  };


  const saveReport = async () => {
    try {
      const updatedData = {
        _id: reportId,
        updatedSiteReportData: {
          title: title,
          description: description,
          project: selectedProject,
          organization: selectedOrganization,
          startDate: startDate ? startDate.toDate() : null,
          endDate: endDate ? endDate.toDate() : null,
          type: "Customer Report",
        },
      };

      const { data } = await Api.post("/site-report/update", updatedData);

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Customer report updated successfully",
        },
      });
      history.goBack();
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update customer report",
        },
      });
    }
  };

  const handleViewUpdates = () => {
    setDrawerOpen(true);
  };

  const handleUpdateSelection = (
    newSelectedTasks,
    newSelectedProgressUpdates,
    newSelectedDocs
  ) => {
    setSelectedTasks(newSelectedTasks);
    setSelectedProgressUpdates(newSelectedProgressUpdates);
    setSelectedDocs(newSelectedDocs);
    setRefreshTaskUpdates((prev) => prev + 1); // Trigger refresh of TaskUpdates
  };

  useEffect(() => {
    console.log("isExternal", isExternal);
  }, [isExternal]);

  const handlePublicity = (e) => {
    e.preventDefault();
    setIsExternal((prev) => !prev);
    saveExternalReport();
    setShowPublicDialog(false);
  };

  const handleClose=()=>{
    setShowPublicDialog(false);
    setIsExternal(isExternal);
  }

  return (
    <StandardContainerWithCustomAppBar
      appBarContent={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <KeyboardBackspace style={{ size: 30, color: "#1976d2" }} />
          </span>
          <DuoButtonGroup
            primaryButtonText="Save"
            primaryButtonListener={() => saveReport()}
            hideSecondary={true}
            disableMargins={true}
          />
        </Box>
      }
    >
      <div className={classes.container}>
        <FormBox label="Customer Report">
          <Typography variant="subtitle1">
            Please fill up the following details
          </Typography>
        </FormBox>
        <div>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={isExternal} onChange={()=> setShowPublicDialog(true)} />}
            label="External"
            labelPlacement="start"
            onChange={() => setShowPublicDialog(true)}
          />
          <Dialog
            open={showPublicDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you Sure?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {isExternal? 'not': ''} make it available externally ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handlePublicity} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormBox label="Title">
            <TextField
              placeholder="Report Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </FormBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormBox label="Description">
            <TextField
              placeholder="Report Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </FormBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormBox label="Project">
            <ProjectPickerDropdown
              fullWidth={true}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              hideLabel={true}
              defaultProjectId={defaultProjectId}
            />
          </FormBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormBox label="Organization">
            <OrgPickerDropdown
              fullWidth={true}
              selectedOrg={selectedOrganization}
              setSelectedOrg={setSelectedOrganization}
              hideLabel={true}
              defaultOrganizationId={defaultOrganizationId}
            />
          </FormBox>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <FormBox label="Start Date">
            <DatePicker
              value={startDate}
              onChange={(date) => {
                console.log(
                  "New start date:",
                  date ? date.format("YYYY-MM-DD") : null
                );
                setStartDate(date);
              }}
            />
          </FormBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormBox label="End Date">
            <DatePicker
              value={endDate}
              onChange={(date) => {
                console.log(
                  "New end date:",
                  date ? date.format("YYYY-MM-DD") : null
                );
                setEndDate(date);
              }}
            />
          </FormBox>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleViewUpdates}>
          View Updates
        </Button>
      </Box>
      <Box>
        <FormBox label="Task Updates" width="95%">
          <TaskUpdates
            reportId={reportId}
            refreshTrigger={refreshTaskUpdates}
          />
        </FormBox>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <ProgressUpdatesDrawer
          project={selectedProject}
          startDate={startDate}
          endDate={endDate}
          onClose={() => setDrawerOpen(false)}
          reportId={reportId}
          initialSelectedTasks={selectedTasks}
          initialSelectedProgressUpdates={selectedProgressUpdates}
          initialSelectedDocs={selectedDocs}
          onUpdateSelection={handleUpdateSelection}
        />
      </Drawer>
    </StandardContainerWithCustomAppBar>
  );
};

export default EditCustomerReport;
