const BasicUserData = {
  firstName: {
    type: "textField",
    value: "",
    lable: "First Name",
    width: "33.33%",
  },
  middleName: {
    type: "textField",
    value: "",
    lable: "Middle Name",
    width: "33.33%",
  },
  lastName: {
    type: "textField",
    value: "",
    lable: "Last Name",
    width: "33.33%",
  },
  mailId: {
    type: "textField",
    value: "",
    lable: "Mail Id",
    width: "50%",
  },
  phone: {
    type: "textField",
    value: "",
    lable: "Phone No.",
    width: "50%",
  },
  city: {
    type: "textField",
    value: "",
    lable: "City",
    width: "50%",
  },
  postalCode: {
    type: "textField",
    value: "",
    lable: "Postal Code",
    width: "50%",
  },
  state: {
    type: "textField",
    value: "",
    lable: "State",
    width: "50%",
  },
  country: {
    type: "textField",
    value: "",
    lable: "Country",
    width: "50%",
  },
  street: {
    type: "textField",
    value: "",
    lable: "Street Address",
    width: "50%",
  },
  taxId: {
    type: "textField",
    value: "",
    lable: "Tax Id",
    width: "50%",
  },
  pan: {
    type: "textField",
    value: "",
    lable: "Pan",
    width: "50%",
  },
  // street: {
  //   type: "textField",
  //   value: "",
  //   lable: "Street Address",
  //   width: "100%",
  // },
  // city: {
  //   type: "textField",
  //   value: "",
  //   lable: "City",
  //   width: "33.33%",
  // },
  // postalCode: {
  //   type: "textField",
  //   value: "",
  //   lable: "Postal Code",
  //   width: "33.33%",
  // },
  // state: {
  //   type: "textField",
  //   value: "",
  //   lable: "State",
  //   width: "33.33%",
  // },
  // country: {
  //   type: "textField",
  //   value: "",
  //   lable: "Country",
  //   width: "50%",
  // },
  // hourlyRate: {
  //   type: "numberField",
  //   value: "",
  //   lable: "hourly Rate",
  //   width: "50%",
  // },
  // costCode: {
  //   type: "numberField",
  //   value: "",
  //   lable: "Cost Code",
  //   width: "50%",
  // },
};

const BasicOrgData = {
  displayName: {
    type: "textField",
    value: "",
    lable: "Name",
    width: "100%",
  },
  mailId: {
    type: "textField",
    value: "",
    lable: "Mail Id",
    width: "50%",
  },
  phone: {
    type: "textField",
    value: "",
    lable: "Phone No.",
    width: "50%",
  },
  street: {
    type: "textField",
    value: "",
    lable: "Street Address",
    width: "100%",
  },
  city: {
    type: "textField",
    value: "",
    lable: "City",
    width: "33.33%",
  },
  postalCode: {
    type: "textField",
    value: "",
    lable: "Postal Code",
    width: "33.33%",
  },
  state: {
    type: "textField",
    value: "",
    lable: "State",
    width: "33.33%",
  },
  country: {
    type: "textField",
    value: "",
    lable: "Country",
    width: "50%",
  },
  pan: {
    type: "textField",
    value: "",
    lable: "Pan",
    width: "50%",
  },
  taxId: {
    type: "textField",
    value: "",
    lable: "Tax Id",
    width: "50%",
  },
  website: {
    type: "textField",
    value: "",
    lable: "Website",
    width: "50%",
  },
  // hourlyRate: {
  //   type: "numberField",
  //   value: "",
  //   lable: "hourly Rate",
  //   width: "50%",
  // },
  // costCode: {
  //   type: "numberField",
  //   value: "",
  //   lable: "Cost Code",
  //   width: "50%",
  // },
};

const BasicGroupData = {
  displayName: {
    type: "textField",
    value: "",
    lable: "Name",
    width: "100%",
  },
  mailId: {
    type: "textField",
    value: "",
    lable: "Mail Id",
    width: "50%",
  },
  phone: {
    type: "textField",
    value: "",
    lable: "Phone No.",
    width: "50%",
  },
  hourlyRate: {
    type: "numberField",
    value: "",
    lable: "hourly Rate",
    width: "50%",
  },
  costCode: {
    type: "numberField",
    value: "",
    lable: "Cost Code",
    width: "50%",
  },
  taxId: {
    type: "textField",
    value: "",
    lable: "Tax Id",
    width: "50%",
  },
};

// const prospectFields = {
//     potentialDeal: {
//         type: "textField",
//         value: "",
//         lable: "Potential Deal",
//         width: "50%"
//     },
//     budget: {
//         type: "numberField",
//         value: 0,
//         lable: "Budget",
//         width: "50%"
//     },
//     expectedCloseDate: {
//         type: "dateField",
//         value: new Date(),
//         lable: "Expected Close Date",
//         width: "50%"
//     },
//     followUpDate: {
//         type: "dateField",
//         value: new Date(),
//         lable: "Follow Up Date",
//         width: "50%"
//     },
//     decisionMakerName: {
//         type: "textField",
//         value: "",
//         lable: "Decision Maker Name",
//         width: "50%"
//     },
//     decisionMakerTitle: {
//         type: "textField",
//         value: "",
//         lable: "Decision Maker Title",
//         width: "50%"
//     },
//     projectInterest: {
//         type: "textField",
//         value: "",
//         lable: "Project Interest",
//         width: "50%"
//     },
//     leadSource: {
//         type: "textField",
//         value: "",
//         lable: "Lead Source",
//         width: "50%"
//     },
//     contactMethod: {
//         type: "textArea",
//         dataType: "text",
//         lable: "Contact Method",
//         row: 3,
//         width: "50%"
//     },
//     meetingNotes: {
//         type: "textArea",
//         value: "",
//         lable: "Meeting Notes",
//         row: 3,
//         width: "50%"
//     },
//     marketingCampaign: {
//         type: "textField",
//         value: "",
//         lable: "Marketing Campaign",
//         width: "50%"
//     },
//     communicationPreferences: {
//         type: "textField",
//         value: "",
//         lable: "Communication Preferences",
//         width: "50%"
//     },
//     decisionFactors: {
//         type: "textArea",
//         value: "",
//         lable: "Decision Factors",
//         row: 3,
//         width: "50%"
//     },
//     competitorAnalysis: {
//         type: "textArea",
//         value: "",
//         lable: "Competitor Analysis",
//         row: 3,
//         width: "50%"
//     },

// }

const brokerFields = {
  licenseNumber: {
    type: "textField",
    value: "",
    lable: "License Number",
    width: "50%",
  },
  brokerageFirm: {
    type: "textField",
    value: "",
    lable: "Brokerage Firm",
    width: "50%",
  },
  specialization: {
    type: "select",
    value: "",
    lable: "Specialization",
    width: "50%",
    options: [
      "Residential",
      "Commercial",
      "Industrial",
      "Land",
      "Luxury",
      "Other",
    ],
  },
  yearsOfExperience: {
    type: "numberField",
    value: "",
    lable: "Years of Experience",
    width: "50%",
  },
  commissionRate: {
    type: "numberField",
    value: "",
    lable: "Commission Rate (%)",
    width: "50%",
  },
  websiteUrl: {
    type: "textField",
    value: "",
    lable: "Website URL. (Must a valid url)",
    width: "50%",
  },
};

const prospectFields = {
  requirementTitle: {
    type: "text",
    value: "",
    lable: "Requirement Title",
    width: "50%",
  },
  requirementType: {
    type: "text",
    value: "",
    lable: "Requirement Type",
    width: "50%",
  },
  requirementPriceStart: {
    type: "text",
    value: "",
    lable: "Price Start",
    width: "50%",
  },
  requirementPriceEnd: {
    type: "text",
    value: "",
    lable: "Price End",
    width: "50%",
  },
  requirementLocation: {
    type: "text",
    value: "",
    lable: "Price End",
    width: "50%",
  },
  requirementPropertyType: {
    type: "text",
    value: "",
    lable: "Property Type",
    width: "50%",
  },
  requirementPropertyArea: {
    type: "text",
    value: "",
    lable: "Property Area",
    width: "50%",
  },
  requirementPropertyFurnishing: {
    type: "text",
    value: "",
    lable: "Property Furnishing",
    width: "50%",
  },
};

const customerFields = {
  SelfEmployedOrSalaried: {
    type: "textArea",
    value: "",
    lable: "Self Employed/  Salaried",
    width: "50%",
  },
  // Source: {
  //   type: "select",
  //   value: "",
  //   lable: "Source",
  //   width: "50%",
  // },
  BankerName: {
    type: "textField",
    value: "",
    lable: "Banker name",
    width: "50%",
  },
  subSource: {
    type: "textField",
    value: "",
    lable: "Sub Source",
    width: "50%",
  },
};

const ownerFields = {
  SelfEmployedOrSalaried: {
    type: "textArea",
    value: "",
    lable: "Self Employed/  Salaried",

    width: "50%",
  },
  // Source: {
  //   type: "select",
  //   value: "",
  //   lable: "Source",
  //   width: "50%",
  // },
  BankerName: {
    type: "textField",
    value: "",
    lable: "Banker name",
    width: "50%",
  },
  subSource: {
    type: "textField",
    value: "",
    lable: "Sub Source",
    width: "50%",
  },
};

const employeeFields = {
  position: {
    type: "textField",
    value: "",
    lable: "Position",
    width: "33.33%",
  },
  department: {
    type: "textField",
    value: null,
    lable: "department",
    width: "33.33%",
  },
  salary: {
    type: "numberField",
    value: 0,
    lable: "Salary",
    width: "33.33%",
  },
  manager: {
    type: "textField",
    value: null,
    lable: "Manager",
    width: "33.33%",
  },
  hireDate: {
    type: "dateField",
    value: new Date(),
    lable: "Hire Date",
    width: "33.33%",
  },
  employeeID: {
    type: "textField",
    value: "",
    lable: "Employee ID",
    width: "33.33%",
  },
  performanceReviews: {
    type: "textArea",
    value: "",
    lable: "Performance Reviews",
    row: 2,
    width: "100%",
  },
  skills: {
    type: "textArea",
    value: "",
    lable: "Skills",
    row: 3,
    width: "50%",
  },
  trainingHistory: {
    type: "textArea",
    value: "",
    lable: "Training History",
    row: 3,
    width: "50%",
  },
  emergencyContact: {
    type: "textField",
    value: 0,
    lable: "Emergency Contact",
    width: "50%",
  },
  workLocation: {
    type: "textField",
    value: null,
    lable: "Work Location",
    width: "50%",
  },
  professionalMemberships: {
    type: "textField",
    value: "",
    lable: "Professional Memberships",
    width: "50%",
  },
  healthInsuranceProvider: {
    type: "textField",
    value: "",
    lable: "Health Insurance Provider",
    width: "50%",
  },
  employeeBenefits: {
    type: "textArea",
    value: "",
    lable: "Work Location",
    row: 3,
    width: "50%",
  },
  currentProjects: {
    type: "textArea",
    value: "",
    lable: "Current Projects",
    row: 3,
    width: "50%",
  },
  workAnniversaryDate: {
    type: "dateField",
    value: new Date(),
    lable: "Work Anniversary Date",
  },
};

const contractorFields = {
  licenseNumber: {
    type: "textField",
    value: "",
    lable: "License Number",
    width: "100%",
  },
  servicesProvided: {
    type: "textArea",
    value: "",
    lable: "Services Provided",
    row: 2,
    width: "50%",
  },
  insuranceDetails: {
    type: "textArea",
    value: "",
    lable: "Insurance Details",
    row: 2,
    width: "50%",
  },
  projectHistory: {
    type: "textArea",
    value: "",
    lable: "Project History",
    row: 2,
    width: "50%",
  },
  certifications: {
    type: "textArea",
    value: "",
    lable: "Certifications",
    row: 2,
    width: "50%",
  },
  qualityAssuranceProgram: {
    type: "textArea",
    value: "",
    lable: "Quality Assurance Program",
    row: 2,
    width: "50%",
  },
  safetyRecord: {
    type: "textArea",
    value: "",
    lable: "Safety Record",
    row: 2,
    width: "50%",
  },
  projectManager: {
    type: "textField",
    value: "",
    lable: "Project Manager",
    width: "50%",
  },
  insuranceExpirationDate: {
    type: "dateField",
    value: new Date(),
    lable: "Insurance Expiration Date",
    width: "50%",
  },
  subcontractors: {
    type: "textArea",
    value: "",
    lable: "Subcontractors",
    row: 2,
    width: "50%",
  },
  equipmentOwned: {
    type: "textArea",
    value: "",
    lable: "Equipment Owned",
    row: 2,
    width: "50%",
  },
  billingInformation: {
    type: "textArea",
    value: "",
    lable: "Billing Information",
    row: 2,
    width: "33.33%",
  },
  clientReferences: {
    type: "textArea",
    value: "",
    lable: "Client References",
    row: 2,
    width: "33.33%",
  },
  completedProjects: {
    type: "textArea",
    value: "",
    lable: "Completed Projects",
    row: 2,
    width: "33.33%",
  },
  employeeTrainingPrograms: {
    type: "textArea",
    value: "",
    lable: "Employee Training Programs",
    row: 2,
    width: "50%",
  },
  preferredCommunicationChannels: {
    type: "textArea",
    value: "",
    lable: "Preferred Communication Channels",
    row: 2,
    width: "50%",
  },
};

const vendorFields = {
  vendorType: {
    type: "textField",
    value: "",
    lable: "Vendor Type",
    width: "50%",
  },
  leadTime: {
    type: "textField",
    value: "",
    lable: "Lead Time",
    width: "50%",
  },
  productsProvided: {
    type: "textArea",
    value: "",
    lable: "Products Provided",
    width: "50%",
  },
  paymentTerms: {
    type: "textArea",
    value: "",
    lable: "Payment Terms",
    width: "50%",
  },
  preferredCommunication: {
    type: "textArea",
    value: "",
    lable: "Preferred Communication",
    width: "50%",
  },
  accountManager: {
    type: "textField",
    value: "",
    lable: "Account Manager",
    width: "33.33%",
  },
  vendorRating: {
    type: "numberField",
    value: "",
    lable: "Vendor Rating",
    width: "33.33%",
  },
  lastInteractionDate: {
    type: "dateField",
    value: new Date(),
    lable: "Last Interaction Date",
    width: "33.33%",
  },
  deliveryPerformance: {
    type: "textField",
    value: "",
    lable: "Delivery Performance",
    row: 3,
    width: "33.33%",
  },
  productCatalog: {
    type: "textArea",
    value: "",
    lable: "Product Catalog",
    row: 3,
    width: "33.33%",
  },
  contractNegotiationHistory: {
    type: "textArea",
    value: "",
    lable: "Contract Negotiation History",
    row: 3,
    width: "33.33%",
  },
  supplyChainDetails: {
    type: "textArea",
    value: "",
    lable: "Supply Chain Details",
    row: 2,
    width: "50%",
  },
  returnsPolicy: {
    type: "textArea",
    value: "",
    lable: "Returns Policy",
    row: 2,
    width: "50%",
  },
  customerServiceContact: {
    type: "textArea",
    value: "",
    lable: "Customer Service Contact",
    row: 2,
    width: "50%",
  },
  vendorCertifications: {
    type: "textArea",
    value: "",
    lable: "Vendor Certifications",
    row: 2,
    width: "50%",
  },
};

const investorFields = {
  investmentAmount: {
    type: "numberField",
    value: "",
    lable: "investmentAmount",
    width: "33.33%",
  },
  investmentDate: {
    type: "dateField",
    value: new Date(),
    lable: "Investment Date",
    width: "33.33%",
  },
  equityPercentage: {
    type: "numberField",
    value: "",
    lable: "Equity Percentage",
    width: "33.33%",
  },
  dividendHistory: {
    type: "textArea",
    value: "",
    lable: "Dividend History",
    row: 3,
    width: "33.33%",
  },
  investmentStrategy: {
    type: "textArea",
    value: "",
    lable: "Investment Strategy",
    row: 3,
    width: "33.33%",
  },
  investmentPortfolio: {
    type: "textArea",
    value: "",
    lable: "Investment Portfolio",
    row: 3,
    width: "33.33%",
  },
  riskAppetite: {
    type: "textField",
    value: "",
    lable: "Risk Appetite",
    row: 3,
    width: "50%",
  },
  investmentStatus: {
    type: "textField",
    value: "",
    lable: "Investment Status",
    row: 3,
    width: "50%",
  },
  exitStrategy: {
    type: "textArea",
    value: "",
    lable: "Exit Strategy",
    row: 3,
    width: "33.33%",
  },
  investmentTimeline: {
    type: "textArea",
    value: "",
    lable: "Investment Timeline",
    row: 3,
    width: "33.33%",
  },
  investorMeetings: {
    type: "textArea",
    value: "",
    lable: "Investor Meetings",
    row: 3,
    width: "33.33%",
  },
  preferredCommunicationChannels: {
    type: "textArea",
    value: "",
    lable: "Preferred Communication Channels",
    row: 2,
    width: "50%",
  },
  investmentPreferences: {
    type: "textArea",
    value: "",
    lable: "Investment Preferences",
    row: 2,
    width: "50%",
  },
};

const lenderFields = {
  loanAmount: {
    type: "numberField",
    value: "",
    lable: "Loan Amount",
    width: "33.33%",
  },
  interestRate: {
    type: "numberField",
    value: "",
    lable: "Interest Rate",
    width: "33.33%",
  },
  loanTerm: {
    type: "numberField",
    value: "",
    lable: "Loan Term",
    width: "33.33%",
  },
  loanOriginationDate: {
    type: "dateField",
    value: new Date(),
    lable: "Loan Origination Date",
    width: "33.33%",
  },
  loanApprovalDate: {
    type: "dateField",
    value: new Date(),
    lable: "Loan Approval Date",
    width: "33.33%",
  },
  loanClosureDate: {
    type: "dateField",
    value: new Date(),
    lable: "Loan Closure Date",
    width: "33.33%",
  },
  loanStatus: {
    type: "textField",
    value: "",
    lable: "Loan Status",
    width: "50%",
  },
  repaymentSchedule: {
    type: "textField",
    value: "",
    lable: "Repayment Schedule",
    width: "50%",
  },
  collateralDetails: {
    type: "textArea",
    value: "",
    lable: "Collateral Details",
    row: 3,
    width: "33.33%",
  },
  loanPurpose: {
    type: "textArea",
    value: "",
    lable: "Loan Purpose",
    row: 3,
    width: "33.33%",
  },
  loanConditions: {
    type: "textArea",
    value: "",
    lable: "Loan Conditions",
    row: 3,
    width: "33.33%",
  },
  legalDueDiligenceDocuments: {
    type: "textArea",
    value: "",
    lable: "Legal Due Diligence Documents",
    row: 2,
    width: "50%",
  },
  creditScoreRequirements: {
    type: "textArea",
    value: "",
    lable: "Credit Score Requirements",
    row: 2,
    width: "50%",
  },
  loanSecurityDetails: {
    type: "textArea",
    value: "",
    lable: "Loan Security Details",
    row: 2,
    width: "50%",
  },
  loanUtilizationPlan: {
    type: "textArea",
    value: "",
    lable: "Loan Utilization Plan",
    row: 2,
    width: "50%",
  },
};

const tenantFields = {
  leaseStartDate: {
    type: "dateField",
    value: new Date(),
    lable: "Lease Start Date",
    width: "33.33%",
  },
  leaseEndDate: {
    type: "dateField",
    value: new Date(),
    lable: "Lease End Date",
    width: "33.33%",
  },
  securityDeposit: {
    type: "numberField",
    value: "",
    lable: "Security Deposit",
    width: "33.33%",
  },
  leaseTerms: {
    type: "textField",
    value: "",
    lable: "Lease Terms",
    width: "33.33%",
  },
  utilitiesIncluded: {
    type: "textField",
    value: "",
    lable: "Utilities Included",
    width: "33.33%",
  },
  petsAllowed: {
    type: "textField",
    value: "",
    lable: "Pets Allowed",
    width: "33.33%",
  },
  maintenanceResponsibility: {
    type: "textField",
    value: "",
    lable: "Maintenance Responsibility",
    width: "33.33%",
  },
  noticePeriod: {
    type: "numberField",
    value: "",
    lable: "Notice Period",
    width: "33.33%",
  },
  leaseTerminationDate: {
    type: "dateField",
    value: new Date(),
    lable: "Lease Termination Date",
    width: "33.33%",
  },
  tenantPortalLogin: {
    type: "textField",
    value: "",
    lable: "Tenant Portal Login",
    width: "50%",
  },
  emergencyMaintenanceContact: {
    type: "textField",
    value: "",
    lable: "Emergency Maintenance Contact",
    width: "50%",
  },
  preferredCommunicationChannels: {
    type: "textArea",
    value: "",
    lable: "Preferred Communication Channels",
    row: 3,
    width: "33.33%",
  },
  insuranceDetails: {
    type: "textArea",
    value: "",
    lable: "Insurance Details",
    row: 3,
    width: "33.33%",
  },
  improvementRequests: {
    type: "textArea",
    value: "",
    lable: "Improvement Requests",
    row: 3,
    width: "33.33%",
  },
};

const workerFields = {
  jobRole: {
    type: "textField",
    value: "",
    lable: "Job Role",
    width: "100%",
  },
  projectAssigned: {
    type: "textArea",
    value: "",
    lable: "Project Assigned",
    row: 2,
    width: "33.33%",
  },
  certifications: {
    type: "textArea",
    value: "",
    lable: "Certifications",
    row: 2,
    width: "50%",
  },
  workSchedule: {
    type: "textField",
    value: "",
    lable: "Work Schedule",
    width: "33.33%",
  },
  safetyTraining: {
    type: "textField",
    value: "",
    lable: "Safety Training",
    width: "33.33%",
  },
  uniformIssued: {
    type: "textField",
    value: "",
    lable: "Uniform Issued",
    width: "33.33%",
  },
  equipmentIssued: {
    type: "textArea",
    value: "",
    lable: "Equipment Issued",
    row: 2,
    width: "50%",
  },
  performanceMetrics: {
    type: "textArea",
    value: "",
    lable: "Performance Metrics",
    row: 2,
    width: "50%",
  },
  overtimeEligibility: {
    type: "textField",
    value: "",
    lable: "Overtime Eligibility",
    width: "33.33%",
  },
  onboardingDate: {
    type: "dateField",
    value: new Date(),
    lable: "Onboarding Date",
    width: "33.33%",
  },
  offboardingDate: {
    type: "dateField",
    value: new Date(),
    lable: "Offboarding Date",
    width: "33.33%",
  },
  employeeRecognitionPrograms: {
    type: "textArea",
    value: "",
    lable: "Employee Recognition Programs",
    row: 2,
    width: "50%",
  },
  teamBuildingActivities: {
    type: "textArea",
    value: "",
    lable: "Team Building Activities",
    row: 2,
    width: "50%",
  },
  internalTrainingPrograms: {
    type: "textArea",
    value: "",
    lable: "Internal Training Programs",
    row: 3,
    width: "50%",
  },
  employeeEngagementSurveys: {
    type: "textArea",
    value: "",
    lable: "Employee Engagement Surveys",
    row: 3,
    width: "50%",
  },
  remoteWorkPolicy: {
    type: "textArea",
    value: "",
    lable: "Remote Work Policy",
    row: 3,
    width: "50%",
  },
};

export {
  BasicUserData,
  BasicOrgData,
  BasicGroupData,
  prospectFields,
  customerFields,
  employeeFields,
  contractorFields,
  vendorFields,
  investorFields,
  lenderFields,
  tenantFields,
  workerFields,
  ownerFields,
  brokerFields,
};
