import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  AvatarGroup,
  Chip,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Api from '../../helpers/Api';
import dayjs from 'dayjs';
import arrayToReducer from '../../helpers/arrayToReducer';
import clsx from "clsx";
import HorizBox from "../styled/generic/HorizBox";


const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "500px",
    overflow: "auto",
    padding: "0",
  },
  summaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1, 0),
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  accordion: {
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(1),
    boxShadow: "0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 7px 14px rgba(0,0,0,0.12), 0 5px 5px rgba(0,0,0,0.11)",
    },
    "&:before": {
      display: "none",
    },
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
  },
  accordionDetails: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  timeline: {
    padding: "0",
    margin: "0",
    "& .MuiTimelineItem-root::before": {
      flex: 0,
      padding: 0,
    },
  },
  timelineContent: {
    padding: "0 16px 16px 16px",
    width: "100%",
  },
  progressText: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
  container: {
    border: "solid 1px gray",
    borderRadius: "1rem",
    margin: "8px",
    padding: "2px",
  },
  usernameText: {
    fontSize: ".9rem !important",
    fontWeight: 'normal !important',
    marginBottom: "4px !important",
  },
  contactText: {
    fontSize: "1rem !important",
    fontWeight: 'normal !important',
    marginBottom: "4px !important",
  },
  documentGrid: {
    marginTop: "16px",
  },
  documentItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  documentImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  goodSpacing: {
    margin: "6px",
  },
  completedBg: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    color: '#ffffff',
  },
  greenBackground: {
    backgroundColor: 'green',
  },
  purpleBackground: {
    backgroundColor: 'gray',
  },
  taskUpdatesTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
}));

const TaskUpdateDisplay = ({ reportId, refreshTrigger }) => {
  const classes = useStyles();
  const [taskUpdates, setTaskUpdates] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchTaskUpdates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Api.post('/site-report/get-by-id', { reportId });

      const { TaskReport, tasks } = response.data || {};
      const { newDict} = arrayToReducer(tasks)

      const groupedUpdates = (TaskReport || []).reduce((acc, item) => {
        if (!item || !item.progressUpdate || !item.progressUpdate.parent) {
          return acc;
        }
        const taskId = item.progressUpdate.parent._id;
        if (!acc[taskId]) {
          const task = newDict[taskId] || {}
          let labels = task?.labels || [];
          acc[taskId] = {
            ...task,
            taskName: item.progressUpdate.parent.taskName || "Unknown Task",
            updates: [],
          };
        }
        acc[taskId].updates.push({
          ...item.progressUpdate,
          docs: item.docs,
          creator: item?.progressUpdate?.creator?.parent, // Add docs from TaskReport item
        });
        return acc;
      }, {});

      setTaskUpdates(groupedUpdates);
    } catch (error) {
      console.error("Error fetching task updates:", error);
    } finally {
      setLoading(false);
    }
  }, [reportId]);

  useEffect(() => {
    fetchTaskUpdates();
  }, [refreshTrigger]);

  const renderDocuments = (docs) => {
    if (!docs || docs.length === 0) {
      return null;
    }

    return (
      <Grid container spacing={2} className={classes.documentGrid}>
        {docs.map((doc, docIndex) => (
          <Grid
            item
            xs={4}
            key={doc._id || docIndex}
            className={classes.documentItem}
          >
            {doc.files && doc.files.length > 0 && doc.files[0].url ? (
              <img
                src={doc.files[0].url}
                alt={doc.title || `Document ${docIndex + 1}`}
                className={classes.documentImage}
              />
            ) : (
              <Typography variant="caption">No file available</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    );
  };

  useEffect(()=>{
    console.log('taskUpdatestaskUpdates=', taskUpdates);
  }, [taskUpdates])

  const renderProgressTimeline = (updates) => {
    console.log("updatesupdates-", updates);
    return (
      <Timeline className={classes.timeline} align="left">
        {updates.map((update, index) => {
          const creator = update?.creator;
          return (
            <TimelineItem
              key={update._id || index}
              className={classes.goodSpacing}
            >
              <TimelineSeparator className={classes.goodSpacing}>
                <TimelineDot color="primary" />
                {index < updates.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent
                className={clsx(classes.timelineContent, classes.container)}
              >
                <div className={classes.taskUpdatesTop}>
                  <Box className={classes.userInfo}>
                    <Avatar
                      src={
                        creator?.displayPicture?.thumbUrl ||
                        creator?.displayPicture?.url
                      }
                      alt={creator?.displayName}
                      className={classes.avatar}
                    />
                    <Typography variant="h6">
                      {creator?.displayName || "Unknown User"}
                    </Typography>
                  </Box>
                  <div>
                    {/* <div
                      className={clsx(
                        classes.completedBg,
                        update?.isCompleted
                          ? classes.greenBackground
                          : classes.purpleBackground
                      )}
                    >
                      <Typography variant="subtitle2" style={{ color: 'white' }}>
                        {update?.isCompleted ? "Completed" : "Pending"}
                      </Typography>
                    </div> */}
                  </div>
                </div>
                {/* <p className={classes.usernameText}>
                  Username: {creator.username}
                </p> */}
                <Typography className={classes.progressText}>
                  Progress: {update.progress || 0}%
                </Typography>
                <Typography variant="caption">
                  {update.createdAt
                    ? dayjs(update.createdAt).format("DD MMM YYYY, HH:mm")
                    : "Date unknown"}
                </Typography>
                <div>
                  <Typography>Documents:</Typography>
                {renderDocuments(update.docs)}
                </div>
                {update.comment && (
                  <Typography variant="body2" gutterBottom>
                    Comment: {update.comment}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };

  const renderTaskAccordions = () =>
    Object.entries(taskUpdates).map(([taskId, task]) => {

      return (
        <Accordion key={taskId} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <Box className={classes.summaryContent}>
            <HorizBox alignItems="center">
              <Avatar
                src={task.creator?.parent?.displayPicture?.url}
                alt={task.creator?.parent?.displayName}
                className={classes.avatar}
              />
              <Box>
                <Typography variant="subtitle1">{task.taskName}</Typography>
                <Typography variant="caption">{task.creator?.parent?.displayName}</Typography>
              </Box>
            </HorizBox>
            <Box>
              <HorizBox>
                {task?.labels?.slice(0, 2).map((item, index) => (
                  <Chip
                    key={index}
                    label={item?.name}
                    size="small"
                    style={{
                      backgroundColor: item?.colorCode + "33",
                      color: item?.colorCode,
                      marginRight: "4px",
                    }}
                  />
                ))}
                {task?.labels?.length > 2 && (
                  <Chip
                    label={`+${task.labels.length - 2}`}
                    size="small"
                    variant="outlined"
                  />
                )}
              </HorizBox>
              <AvatarGroup max={3} sx={{ justifyContent: 'flex-end', marginTop: '4px' }}>
                {task?.assigned?.map((user, index) => (
                  <Avatar
                    key={index}
                    src={user?.displayPicture?.url}
                    alt={user?.displayName}
                    sx={{ width: 24, height: 24 }}
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {renderProgressTimeline(task.updates)}
        </AccordionDetails>
      </Accordion>
    )});

  return (
    <Box className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : Object.keys(taskUpdates).length === 0 ? (
        <Typography>No task updates available</Typography>
      ) : (
        renderTaskAccordions()
      )}
    </Box>
  );
};

export default TaskUpdateDisplay;
