import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HorizBox from "../styled/generic/HorizBox";
import ListingPicker from "../styled/generic/ListingPicker";
import SpaceBetween from "../styled/generic/SpaceBetween";
import TextField from "../styled/generic/TextField";
import Select from "../styled/generic/Select";
import { CurrencyRupee } from "@mui/icons-material";
import PropertyTypeChooser from "../listing/editlisting/components/PropertyTypeChooser";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";
import CheckGroup from "../styled/generic/CheckGroup";
import { Avatar, Box, Checkbox, InputAdornment, MenuItem, Typography } from "@mui/material";
import FormBox from "../styled/generic/FormBox";
import CountPicker from "../styled/generic/CountPicker";
import CheckGroupGrid from "../styled/generic/CheckGroupGrid";
import CheckGroupWithObj from "../styled/generic/CheckGroupWithObj";

const REQUIREMENT_TYPE_OPTIONS = [
    "Rent",
    "Sell",
    "Roommate",
    "Job",
    "Investment",
    "Tender",
];

const FURNISHING_OPTIONS = [
    "Fully Furnished",
    "Semi Furnished",
    "Un Furnished",
];

const FLOOR_OPTIONS = [
    { title: "Ground Floor", value: 0 },
    { title: "1st Floor", value: 1 },
    { title: "2nd Floor", value: 2 },
    { title: "3rd Floor", value: 3 },
    { title: "4th Floor", value: 4 },
    { title: "5th or More", value: -1 },
];

const AMENITY_OPTIONS = [
    "Power Backup",
    "Wifi",
    "Gym",
    "Air conditioner",
    "Swimming Pool",
    "Gas Station",
    "Park",
    "Fire Safety",
    "Elevator",
    "Play Area",
    "Park",
];

const useStyles = makeStyles((theme) => ({

}));

export default function ProspectForm({
    prospectData, setProspectData, locationForm
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 2 }}>
                <FormBox label="Make Private">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={prospectData?.isPrivate}
                                onChange={(evt, checked) => {
                                    setProspectData({
                                        ...prospectData,
                                        isPrivate: checked
                                    })
                                }}
                            />
                        }
                        label="Make this requirement private"
                    />
                </FormBox>
                <FormBox label="Listing">
                    {prospectData?.listing?._id ? (
                        <HorizBox
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid #D8DEE4",
                                backgroundColor: "#F5F7FA",
                                display: "inline-flex",
                                px: "10px",
                                py: "10px",
                            }}
                        >
                            <Avatar
                                src={prospectData?.listing?.displayPicture?.url}
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                }}
                            />
                            <span
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    color: "#54555A",
                                }}
                            >
                                {prospectData?.listing?.title}
                            </span>
                            <span
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setProspectData({
                                        ...prospectData,
                                        listing: null
                                    })
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M13.5 4.5L4.5 13.5"
                                        stroke="#717171"
                                        stroke-width="1.125"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M4.5 4.5L13.5 13.5"
                                        stroke="#717171"
                                        stroke-width="1.125"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </span>
                        </HorizBox>
                    ) : (
                        <ListingPicker
                            selectedListing={prospectData?.listing}
                            setSelectedListing={(value) => {
                                setProspectData({
                                    ...prospectData,
                                    listing: value
                                })
                            }}
                            defaultListingId={prospectData?.listing}
                        />
                    )}
                </FormBox>
                <FormBox label="Requirements">
                    <Typography variant="body1" gutterBottom>
                        Tell Us About Your Requirements
                    </Typography>
                </FormBox>
                <SpaceBetween
                    left={
                        <FormBox label="Title">
                            <TextField
                                placeholder="Enter Title"
                                value={prospectData?.title}
                                onChange={(e) =>
                                    setProspectData({
                                        ...prospectData,
                                        title: e.target.value
                                    })
                                }
                                size="small"
                                fullWidth={true}
                            />
                        </FormBox>
                    }
                    right={
                        <FormBox label="Requirement Type">
                            <Select
                                value={prospectData?.requirementType}
                                onChange={(evt) => {
                                    setProspectData({
                                        ...prospectData,
                                        requirementType: evt.target.value
                                    })
                                }}
                                size="small"
                                fullWidth={true}
                            >
                                {REQUIREMENT_TYPE_OPTIONS.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormBox>
                    }
                />
                <FormBox label="Price Range">
                    <SpaceBetween
                        left={
                            <TextField
                                placeholder="Min"
                                type="Number"
                                sx={{ mr: 2 }}
                                value={prospectData?.minPrice}
                                onChange={(e) => {
                                    setProspectData({
                                        ...prospectData,
                                        minPrice: e.target.value
                                    })
                                }}
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupee />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                            />
                        }
                        right={
                            <TextField
                                placeholder="Max"
                                type="Number"
                                value={prospectData?.maxPrice}
                                onChange={(e) => {
                                    setProspectData({
                                        ...prospectData,
                                        maxPrice: e.target.value
                                    })
                                }}
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupee />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                            />
                        }
                    />
                </FormBox>
                <SpaceBetween left={locationForm} />
                <PropertyTypeChooser
                    propertyType={prospectData?.propertyType}
                    setPropertyType={(value) => {
                        setProspectData({
                            ...prospectData,
                            propertyType: value
                        })
                    }}
                />
                <FormBox label="Area">
                    <SpaceBetween
                        left={
                            <TextField
                                placeholder="Min"
                                type="Number"
                                sx={{ mr: 2 }}
                                value={prospectData?.minArea}
                                onChange={(e) => {
                                    setProspectData({
                                        ...prospectData,
                                        minArea: e.target.value
                                    })
                                }}
                                size="small"
                                fullWidth={true}
                            />
                        }
                        right={
                            <TextField
                                placeholder="Max"
                                type="Number"
                                sx={{ mr: 2 }}
                                value={prospectData?.maxArea}
                                onChange={(e) => {
                                    setProspectData({
                                        ...prospectData,
                                        maxArea: e.target.value
                                    })
                                }}
                                size="small"
                                fullWidth={true}
                            />
                        }
                    />
                </FormBox>
                <FormBox label="Area Unit">
                    <SpaceBetween
                        left={
                            <UnitAutocomplete
                                value={prospectData?.areaUnit}
                                onChange={(evt, value) =>
                                    setProspectData({
                                        ...prospectData,
                                        areaUnit: value
                                    })
                                }
                                size="small"
                            />
                        }
                    />
                </FormBox>
                <CheckGroup
                    label="Furnishing"
                    options={FURNISHING_OPTIONS}
                    selectedOptions={prospectData?.furnishings || []}
                    setSelectedOptions={(value) => {
                        setProspectData({
                            ...prospectData,
                            furnishings: value
                        })
                    }}
                />
                <SpaceBetween
                    left={
                        <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Bedrooms"
                            fullwidth={true}
                            count={prospectData?.bedRoomCount}
                            setCount={(val) => {
                                setProspectData({
                                    ...prospectData,
                                    bedRoomCount: parseInt(val || 0)
                                })
                            }}
                            showMoreButton={true}
                        />
                    }
                    right={
                        <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Bathroom"
                            count={prospectData?.bathRoomCount}
                            setCount={(val) => {
                                setProspectData({
                                    ...prospectData,
                                    bathRoomCount: parseInt(val || 0)
                                })
                            }}
                            showMoreButton={true}
                        />
                    }
                />
                <SpaceBetween
                    left={
                        <CountPicker
                            countFrom={1}
                            countTo={5}
                            label="Balcony"
                            count={prospectData?.balconyCount}
                            setCount={(val) => {
                                setProspectData({
                                    ...prospectData,
                                    balconyCount: parseInt(val || 0)
                                })
                            }}
                            showMoreButton={true}
                        />
                    }
                />
                <SpaceBetween
                    alignItems={"flex-start"}
                    left={
                        <CheckGroupWithObj
                            label="Floor"
                            options={FLOOR_OPTIONS}
                            selectedOptions={prospectData?.floors || []}
                            setSelectedOptions={(value) => {
                                setProspectData({
                                    ...prospectData,
                                    floors: value
                                })
                            }}
                        />
                    }
                    right={
                        <CheckGroupGrid
                            label="Amenities"
                            options={AMENITY_OPTIONS}
                            selectedOptions={prospectData?.amenities || []}
                            setSelectedOptions={(value) => {
                                setProspectData({
                                    ...prospectData,
                                    amenities: value
                                })
                            }}
                        />
                    }
                />
            </Box>
        </Box>
    );
}