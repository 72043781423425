import React, { useEffect, useState } from "react";
import { 
  Box, 
  Typography, 
  IconButton, 
  CircularProgress, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Checkbox,
  Button,
  Grid,
  Drawer,
  Avatar
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import Api from "../../helpers/Api";
import dayjs from "dayjs";
import { getProgressColor } from "../../helpers/colorHelper";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";

const ProgressUpdatesDrawer = ({ project, startDate, endDate, onClose, reportId, initialSelectedTasks, onUpdateSelection }) => {
  const [loading, setLoading] = useState(true);
  const [progressUpdates, setProgressUpdates] = useState({});
  const [selectedTasks, setSelectedTasks] = useState(initialSelectedTasks || {});
  const [selectedProgressUpdates, setSelectedProgressUpdates] = useState({});
  const [selectedDocs, setSelectedDocs] = useState({});
  const [progressDrawerOpen, setProgressDrawerOpen] = useState(false);
  const [currentTaskUpdates, setCurrentTaskUpdates] = useState([]);
  const [selectionCounts, setSelectionCounts] = useState({});

  useEffect(() => {
    fetchProgressUpdates();
  }, [project, startDate, endDate]);

  const fetchProgressUpdates = async () => {
    setLoading(true);
    try {
      const data = await Api.post("/wbs/progress-updates", {
        projectId: project,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : null,
        endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
      });
      console.log('datadata=', data);
      const groupedUpdates = data.reduce((acc, update) => {
        if (!acc[update.taskId]) {
          acc[update.taskId] = {
            taskName: update.taskName,
            updates: []
          };
        }
        acc[update.taskId].updates.push({
          ...update,
          creator: {
            name: update.creator?.name || "Unknown",
            displayPicture: update.creator?.displayPicture || null
          }
        });
        return acc;
      }, {});


      Object.values(groupedUpdates).forEach(task => {
        task.updates.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      });
   
      setProgressUpdates(groupedUpdates);
      
      // Initialize selectedTasks, selectedProgressUpdates, and selectedDocs
      const newSelectedTasks = { ...initialSelectedTasks };
      const newSelectedProgressUpdates = {};
      const newSelectedDocs = {};
      const newSelectionCounts = {};
      
      // Object.entries(groupedUpdates).forEach(([taskId, taskData]) => {
      //   if (!(taskId in newSelectedTasks)) {
      //     newSelectedTasks[taskId] = false;
      //   }
      //   newSelectedProgressUpdates[taskId] = {};
      //   newSelectedDocs[taskId] = {};
      //   newSelectionCounts[taskId] = { updates: 0, docs: 0 };
      //   taskData.updates.forEach(update => {
      //     newSelectedProgressUpdates[taskId][update._id] = false;
      //     newSelectedDocs[taskId][update._id] = {};
      //     update.docs.forEach(doc => {
      //       newSelectedDocs[taskId][update._id][doc.id] = false;
      //     });
      //   });
      // });

      Object.entries(groupedUpdates).forEach(([taskId, taskData]) => {
        if (!(taskId in newSelectedTasks)) {
          newSelectedTasks[taskId] = false;
        }
        newSelectedProgressUpdates[taskId] = {};
        newSelectedDocs[taskId] = {};
        newSelectionCounts[taskId] = { updates: 0, docs: 0 };
        taskData.updates.forEach(update => {
          newSelectedProgressUpdates[taskId][update._id] = newSelectedTasks[taskId];
          newSelectedDocs[taskId][update._id] = {};
          update.docs.forEach(doc => {
            newSelectedDocs[taskId][update._id][doc.id] = newSelectedTasks[taskId];
          });
          if (newSelectedTasks[taskId]) {
            newSelectionCounts[taskId].updates++;
            newSelectionCounts[taskId].docs += update.docs.length;
          }
        });
      });
      
      setSelectedTasks(newSelectedTasks);
      setSelectedProgressUpdates(newSelectedProgressUpdates);
      setSelectedDocs(newSelectedDocs);
      setSelectionCounts(newSelectionCounts);

    } catch (error) {
      console.error("Error fetching progress updates:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskSelection = (taskId) => {
    setSelectedTasks(prev => {
      const newSelectedTasks = {
        ...prev,
        [taskId]: !prev[taskId]
      };
      
      const newSelectedProgressUpdates = { ...selectedProgressUpdates };
      const newSelectedDocs = { ...selectedDocs };
      const newSelectionCounts = { ...selectionCounts };
      
      newSelectionCounts[taskId] = { updates: 0, docs: 0 };
      
      progressUpdates[taskId].updates.forEach(update => {
        newSelectedProgressUpdates[taskId][update._id] = newSelectedTasks[taskId];
        if (newSelectedTasks[taskId]) {
          newSelectionCounts[taskId].updates++;
          newSelectionCounts[taskId].docs += update.docs.length;
        }
        update.docs.forEach(doc => {
          newSelectedDocs[taskId][update._id][doc.id] = newSelectedTasks[taskId];
        });
      });

      setSelectedProgressUpdates(newSelectedProgressUpdates);
      setSelectedDocs(newSelectedDocs);
      setSelectionCounts(newSelectionCounts);
      
      onUpdateSelection(newSelectedTasks, newSelectedProgressUpdates, newSelectedDocs);
      return newSelectedTasks;
    });
  };

  const handleProgressUpdateSelection = (taskId, updateId) => {
    if (selectedTasks[taskId]) {
      setSelectedProgressUpdates(prev => {
        const newSelectedUpdates = {
          ...prev,
          [taskId]: {
            ...prev[taskId],
            [updateId]: !prev[taskId]?.[updateId]
          }
        };
        
        const newSelectedDocs = { ...selectedDocs };
        const newSelectionCounts = { ...selectionCounts };
        
        const update = progressUpdates[taskId].updates.find(u => u._id === updateId);
        if (update) {
          if (newSelectedUpdates[taskId][updateId]) {
            newSelectionCounts[taskId].updates++;
            newSelectionCounts[taskId].docs += update.docs.length;
          } else {
            newSelectionCounts[taskId].updates--;
            newSelectionCounts[taskId].docs -= update.docs.length;
          }
          
          update.docs.forEach(doc => {
            newSelectedDocs[taskId][updateId][doc.id] = newSelectedUpdates[taskId][updateId];
          });
        }
        
        setSelectedDocs(newSelectedDocs);
        setSelectionCounts(newSelectionCounts);
        
        onUpdateSelection(selectedTasks, newSelectedUpdates, newSelectedDocs);
        return newSelectedUpdates;
      });
    }
  };

  const handleDocSelection = (taskId, updateId, docId) => {
    if (selectedTasks[taskId] && selectedProgressUpdates[taskId]?.[updateId]) {
      setSelectedDocs(prev => {
        const updatedDocs = {
          ...prev,
          [taskId]: {
            ...prev[taskId],
            [updateId]: {
              ...prev[taskId]?.[updateId],
              [docId]: !prev[taskId]?.[updateId]?.[docId]
            }
          }
        };
        
        const newSelectionCounts = { ...selectionCounts };
        if (updatedDocs[taskId][updateId][docId]) {
          newSelectionCounts[taskId].docs++;
        } else {
          newSelectionCounts[taskId].docs--;
        }
        
        setSelectionCounts(newSelectionCounts);
        
        onUpdateSelection(selectedTasks, selectedProgressUpdates, updatedDocs);
        return updatedDocs;
      });
    }
  };

  const handleSaveSelection = async () => {


    try {
      console.log('selectedTasks=', selectedTasks);
      const TaskReport = Object.entries(selectedTasks)
        .filter(([taskId, isSelected]) => isSelected)
        .flatMap(([taskId, _]) => {
          console.log('taskId=', taskId);
          
          return Object.entries(selectedProgressUpdates[taskId])
            .filter(([updateId, isSelected]) => isSelected)
            .map(([updateId, _]) => {
              const selectedDocIds = Object.entries(selectedDocs[taskId][updateId] || {})
                .filter(([_, isSelected]) => isSelected)
                .map(([docId, _]) => docId);
              
              return {
                taskId: taskId,
                progressUpdate: updateId,
                docs: selectedDocIds
              };
            });
        });
        console.log('TaskReport=', TaskReport);
        

      const response = await Api.post("/site-report/update-tasks", {
        reportId,
        TaskReport
      });



      if (response.success) {
        console.log("Tasks and documents updated successfully");
        onUpdateSelection(selectedTasks, selectedProgressUpdates, selectedDocs);
        onClose();
      } else {
        console.error("Failed to update tasks and documents");
      }
    } catch (error) {
      console.error("Error updating tasks and documents:", error);
    }
  };

  const handleOpenProgressDrawer = (updates, taskId) => {
    setCurrentTaskUpdates(updates.map(update => ({ ...update, taskId })));
    setProgressDrawerOpen(true);
  };

  const handleCloseProgressDrawer = () => {
    setProgressDrawerOpen(false);
    setCurrentTaskUpdates([]);
  };

  const renderUpdateCard = (update) => {

    return(
    <Box
      mt={2}
      sx={{
        backgroundColor: "#F3F3F3",
        borderRadius: "10px",
        p: 2,
      }}
    >
      <BoxSpaceBetween>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectedProgressUpdates[update.taskId]?.[update._id] || false}
            onChange={() => handleProgressUpdateSelection(update.taskId, update._id)}
            disabled={!selectedTasks[update.taskId]}
          />
          <Avatar 
            src={update.creator?.displayPicture} 
            alt={update.creator?.name}
            sx={{ width: 40, height: 40, marginRight: 2 }}
          />
          <Box>
            <Typography variant="subtitle1">
              {update.creator?.name || "Unknown User"} 
            </Typography>
            <Typography variant="subtitle2">
              {dayjs(update.updatedAt).format("DD MMM YYYY, hh:mm a")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "120px",
            backgroundColor: getProgressColor(update.progress)?.backgroundColor,
            color: getProgressColor(update.progress)?.color,
            borderRadius: "10px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            p: 1,
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "1rem" }}>Work Done</span>
          <span>{update.progress}/100</span>
        </Box>
      </BoxSpaceBetween>
      
      {update.comment && (
        <Box mt={2}>
          <Typography variant="body2">{update.comment}</Typography>
        </Box>
      )}

      {update.docs && update.docs.length > 0 && (
        <Box mt={2}>
          <Typography variant="subtitle2" fontWeight="bold">Documents:</Typography>
          <Grid container spacing={2}>
            {update.docs.map((doc) => (
              <Grid item key={doc.id}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Checkbox
                    checked={selectedDocs[update.taskId]?.[update._id]?.[doc.id] || false}
                    onChange={() => handleDocSelection(update.taskId, update._id, doc.id)}
                    disabled={!selectedTasks[update.taskId] || !selectedProgressUpdates[update.taskId]?.[update._id]}
                  />
                  <img 
                    src={doc.url} 
                    alt={doc.name}
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  )};

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Progress Updates</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : Object.keys(progressUpdates).length > 0 ? (
        <Grid container spacing={2}>
          {Object.entries(progressUpdates).map(([taskId, taskData]) => (
            <Grid item xs={12} sm={6} md={4} key={taskId}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                >
                  <Box display="flex" alignItems="center" width="100%">
                    <Checkbox
                      checked={selectedTasks[taskId] || false}
                      onChange={() => handleTaskSelection(taskId)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Typography variant="subtitle1">{taskData.taskName}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography fontWeight="bold">
                    Updates: {selectionCounts[taskId]?.updates || 0} 
                  </Typography>
                  <Typography fontWeight="bold">
                    Docs: {selectionCounts[taskId]?.docs || 0} 
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => handleOpenProgressDrawer(taskData.updates, taskId)}
                    style={{ marginTop: '10px' }}
                  >
                    View Progress
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No progress updates found for the selected date range.</Typography>
      )}

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSaveSelection}>
          Save Selection
        </Button>
      </Box>

      <Drawer
        anchor="right"
        open={progressDrawerOpen}
        onClose={handleCloseProgressDrawer}
        PaperProps={{ sx: { width: '75%' } }}
      >
        <Box sx={{ p: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Progress Updates</Typography>
            <IconButton onClick={handleCloseProgressDrawer}>
              <Close />
            </IconButton>
          </Box>
          {currentTaskUpdates.map((update, index) => renderUpdateCard(update))}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ProgressUpdatesDrawer;