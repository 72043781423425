import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Select from "./Select";

const ProjectBlockPickerDropdown = ({
  projectId,
  hideLabel,
  size,
  selectedProjectBlock,
  setSelectedProjectBlock,
  fullWidth = false,
}) => {
  const [projectBlocks, setProjectBlocks] = useState([]);

  const getProjectBlocks = async () => {
    if (!projectId) {
      return;
    }

    try {
      const { data } = await Api.post("/public/project/block/get", {
        projectId: projectId,
      });

      if (data && data?.length > 0) {
        setProjectBlocks(data);
        setSelectedProjectBlock(data[0]);
      } else {
        setSelectedProjectBlock("");
        setProjectBlocks([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectBlocks();
  }, [projectId]);

  return (
    <FormControl fullWidth={fullWidth} size={size}>
      {!hideLabel && (
        <InputLabel
          id="demo-simple-select-helper-label"
          shrink={selectedProjectBlock !== null}
        >
          {selectedProjectBlock ? "Project Block" : "Select Project Block"}
        </InputLabel>
      )}

      <Select
        value={selectedProjectBlock}
        onChange={(evt) => setSelectedProjectBlock(evt.target.value)}
        label={selectedProjectBlock ? "Project Block" : "Select Project Block"}
        notched={hideLabel ? false : selectedProjectBlock !== null}
        displayEmpty
      >
        <MenuItem value="">{selectedProjectBlock?.displayName || "Select"}</MenuItem>
        {projectBlocks.length > 0 &&
          projectBlocks.map((obj) => (
            <MenuItem key={obj._id} value={obj}>
              {obj?.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProjectBlockPickerDropdown;
