import { Box, IconButton } from "@mui/material";
import React from "react";
import Image from "./Image";
import { Close } from "@material-ui/icons";

const DeletableImage = ({ src, onDelete, width, height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: width,
          height: height,
        }}
      >
        <img src={src} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box sx={{ ml: 1 }}>
        <IconButton size="small" onClick={() => onDelete()}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DeletableImage;
