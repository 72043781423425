import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ImageVideoViewer from "./ImageVideoViewer";
import DocumentViewer from "./DocumentViewer";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import DocViewer from "./DocViewer";

const FilesViewer = ({
  picVideoViewerHeight,
  picVideoViewerHeightSmall,
  picVideoViewerWidth,
  files = [],
  isDeletable,
  deleteFunction,
  disablePreview,
  disableEditMetaData,
  disableShowMetaData,
  onMetadataChange,
}) => {
  const history = useHistory();

  const [current, setCurrent] = useState(0);
  const [picVideosArr, setPicVideosArr] = useState([]);
  const [docsArr, setDocsArr] = useState([]);

  useEffect(() => {
    let arr1 = [];
    let arr2 = [];

    files.map((file, i) => {
      if (file.type.startsWith("image/")) {
        arr1.push({
          _id: file?._id,
          index: i,
          url: file?.url,
          type: "photo",
          title: file?.title || "",
          description: file?.description || "",
          tag: file?.tag || "",
        });
      } else if (file.type.startsWith("video/")) {
        arr1.push({
          _id: file?._id,
          index: i,
          url: file?.url,
          type: "video",
          title: file?.title || "",
          description: file?.description || "",
          tag: file?.tag || "",
        });
      } else {
        arr2.push({
          ...file,
          title: file?.title || "",
          description: file?.description || "",
          tag: file?.tag || "",
        });
      }
    });

    setPicVideosArr(arr1);
    setDocsArr(arr2);
  }, [files]);

  return (
    <Box>
      <Box>
        {picVideosArr.length > 0 && (
          <ImageVideoViewer
            data={picVideosArr}
            picVideoViewerHeight={picVideoViewerHeight}
            picVideoViewerWidth={picVideoViewerWidth}
            picVideoViewerHeightSmall={picVideoViewerHeightSmall}
            isDeletable={isDeletable}
            removeFun={deleteFunction}
            disablePreview={disablePreview}
            disableEditMetaData={disableEditMetaData}
            disableShowMetaData={disableShowMetaData}
            onMetadataChange={onMetadataChange}
          />
        )}
      </Box>

      <Box sx={{ mt: 2 }} display="flex" alignItems="center" flexWrap="wrap">
        {docsArr?.length > 0 && (
          <DocViewer
            data={docsArr}
            isDeletable={isDeletable}
            disableEditMetaData={disableEditMetaData}
            disableShowMetaData={disableShowMetaData}
            onMetadataChange={onMetadataChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default FilesViewer;
