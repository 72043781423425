import React, { useEffect, useRef, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FormBox from "../styled/generic/FormBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import SpaceBetween from "../styled/generic/SpaceBetween";
import ProjectPickerDropdown from "../styled/generic/ProjectPickerDropdown";
import OrgPickerDropdown from "../styled/generic/OrgPickerDropdown";
import ProjectBlockPickerDropdown from "../styled/generic/ProjectBlockPickerDropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "../styled/generic/DatePicker";
import TextField from "../styled/generic/TextField";
import {
  Add,
  Delete,
  DeleteOutline,
  KeyboardArrowDown,
} from "@material-ui/icons";
import EntitySearch from "../styled/CommonComponents/EntitySearch";
import { useDispatch, useSelector } from "react-redux";
import LabelWithSingleActionButton from "../styled/generic/LabelWithSingleActionButton";
import LabelWithSingleActionIconButton from "../styled/generic/LabelWithSingleActionIconButton";
import DocumentUploader from "../styled/generic/DocumentUploader";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import AllFileViewerFeed from "../styled/CommonComponents/AllFiles.Viewer.Feed";
import { updateDeleteFlagForSingleFiles } from "../propertyManagement/apiCall";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import FilesViewer from "../styled/generic/FilesViewer";
import StandardContainerWithCustomAppBar from "../styled/generic/StandardContainerWithCustomAppBar";
import AppBar from "../styled/generic/AppBar";
import SavedCompareReports from "../ProjectAnalysis/Report.Compare.js/Saved.Compare.Reports";
import Api from "../../helpers/Api";
import dayjs from "dayjs";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import { Close, ConnectWithoutContactOutlined } from "@mui/icons-material";
import useShared from "../share/useShared";
import SharedList from "../share/sharedList";
import Image from "../styled/generic/Image";
import DeletableImage from "../styled/generic/DeletableImage";
import SiteReportDoc from "../doc/SiteReportDoc";
import PaginatedEntityDropdown from "../styled/CommonComponents/PaginatedEntityDropdown";
const mainRoles = [
  "Owner",
  "Admin",
  "Viewer",
  "Employee",
  "Contractor",
  "Worker",
  "Other",
];

const EditSiteReport = () => {
  const { user } = useSelector((state) => state.auth);
  const { fileDictionary, createdFileIds } = useSelector((state) => state.file);
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedProjectBlock, setSelectedProjectBlock] = useState(null);
  const [defaultProjectId, setDefaultProjectId] = useState(null);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState(null);
  const [visitDate, setVisitDate] = useState();
  const [report, setReport] = useState(null);
  const [observers, setObservers] = useState([]);
  const [observations, setObservations] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [expandedUserSearch, setExpandedIserSearch] = useState([{}]);
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesObserver, setFilesObserver] = useState(null);
  const [shared, setShared] = useState([user?.profile]);
  const [isPrivate, setPrivate] = useState(true);
  const [newFileIds, setNewFileIds] = useState([]); // Ids of files that are uploaded but not saved in db yet
  const [updatedFileIds, setUpdatedFileIds] = useState({}); // Ids of files whose meta data is changed
  const [logo, setLogo] = useState(null);

  const addObserver = () => {
    setObservers([...observers, { _id: new Date().toDateString() }]);
  };

  const deleteObserver = (index) => {
    let temp = [...observers];
    temp.splice(index, 1);
    setObservers(temp);
  };

  const updateObserver = (index, data) => {
    let temp = [...observers];
    temp[index] = { ...temp[index], ...data };
    setObservers(temp);
  };

  const addObservation = async () => {
    try {
      const { data } = await Api.post("/site-observation/create", {
        user: user?._id,
        project: selectedProject?._id,
        organization: selectedOrganization?._id,
        projectBlock: selectedProjectBlock?._id,
        parent: reportId,
        parentModelName: "SiteReport",
      });
      setObservations([...observations, { _id: data?._id, files: [] }]);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create observation",
        },
      });
    }
  };

  const deleteObservation = (index) => {
    let temp = [...observations];
    temp.splice(index, 1);
    setObservations(temp);
  };

  const updateObservation = (index, data) => {
    let temp = [...observations];
    temp[index] = { ...temp[index], ...data };
    setObservations(temp);
  };

  const handleExpandUser = (id, action) => {
    let temp = [...expandedUserSearch];
    if (action === "open") {
      temp[id] = true;
    } else {
      temp[id] = false;
    }
    setExpandedIserSearch(temp);
  };

  const removeFun = async (id) => {
    let observerIndex = observations.findIndex(
      (item) => item?._id === filesObserver?._id
    );

    let observer = observations[observerIndex];
    let files = observer?.files || [];
    let newFiles = files.filter((item) => item?._id != id);

    observer.files = newFiles;
    let temp = [...observations];
    temp[observerIndex] = observer;
    setObservations(temp);

    await updateDeleteFlagForSingleFiles({ fileId: id })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (createdFileIds) {
      getAllFiles({ files: createdFileIds })
        .then((data) => {
          setFiles(data);

          let temp = [...observations]; // Copy of current observations

          let index = temp.findIndex(
            (item) => item?._id === filesObserver?._id
          ); // Index of observation whose files are being updated

          // If observation not found, return
          if (index === -1) return console.log("Observation not found");

          let observation = temp[index]; // Observation inside observation array whose files are being updated

          let previousFilesOfThisObservation = [...observation?.files] || []; // Previous files of this observation
          // observation.files is a property that only exists in frontend. In backend, it will be converted to docs

          // Check for duplicates
          let processedFiles = previousFilesOfThisObservation.filter(
            (item) => !createdFileIds.includes(item?._id)
          );

          let oldNew = newFileIds.filter(
            (item) => !createdFileIds.includes(item)
          );
          setNewFileIds([...oldNew, ...createdFileIds]); // Add newly created files to newFileIds array

          let updatedFiles = [...processedFiles, ...data];

          temp[index] = {
            ...temp[index],
            files: updatedFiles,
          };
          setObservations(temp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [createdFileIds]);

  const getReport = async () => {
    try {
      const { data } = await Api.post("/site-report/get-by-id", {
        reportId: reportId,
      });
      setReport(data);
      setTitle(data?.title);
      setDescription(data?.description);
      setSelectedProject(data?.project?._id);
      setSelectedOrganization(data?.organization?._id);
      setDefaultProjectId(data?.project?._id);
      setDefaultOrganizationId(data?.organization?._id);
      setSelectedProjectBlock(data?.projectBlock?._id);
      setVisitDate(dayjs(data?.visitDate));
      setObservers(data?.observers || []);
      setLogo(data?.logo || null);

      if (data?.observations?.length <= 0) {
        addObservation();
      } else {
        let orignalObservations = data?.observations || [];
        let processedObservations = [];
        for (let i = 0; i < orignalObservations.length; i++) {
          let curObservation = orignalObservations[i];
          let curObservationFiles = [];
          let curDocs = curObservation?.docs || [];
          for (let j = 0; j < curDocs.length; j++) {
            let curDoc = curDocs[j];
            let curDocFiles = curDoc?.files || [];
            for (let k = 0; k < curDocFiles.length; k++) {
              let curDocFile = curDocFiles[k];
              let processedFile = {
                ...curDocFile,
                title: curDoc?.title || "",
                description: curDoc?.description || "",
                tag: curDoc?.tag || "",
              };
              curObservationFiles = [...curObservationFiles, processedFile];
            }
          }
          processedObservations.push({
            ...curObservation,
            files: curObservationFiles,
          });
        }
        setObservations(processedObservations);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching report",
        },
      });
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const saveReport = async () => {
    try {
      const { data } = await Api.post("/site-report/update", {
        _id: reportId,
        updatedSiteReportData: {
          title: title,
          description: description,
          user: user?._id,
          project: selectedProject?._id,
          organization: selectedOrganization?._id,
          projectBlock: selectedProjectBlock?._id,
          visitDate: dayjs(visitDate).toDate(),
          observers:
            observers?.map((item) => {
              return {
                profile: item?.profile?._id,
                designation: item?.designation || "",
              };
            }) || [],
          observations: observations?.map((item) => item?._id) || [],
          type: "Site Visit",
          logo: logo?._id,
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update report",
        },
      });
    }
  };

  const saveObservations = async () => {
    try {
      let actualUpdatedFileIds = Object.keys(updatedFileIds).filter(
        (item) => newFileIds.indexOf(item) === -1
      );

      const { data } = await Api.post("/site-observation/update-many", {
        observations: observations,
        newFileIds: newFileIds,
        updatedFileIds: actualUpdatedFileIds,
      });

      setNewFileIds([]);
      setUpdatedFileIds({});
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update observations",
        },
      });
    }
  };

  const saveAll = async () => {
    await Promise.all([saveReport(), saveObservations()]).then(() => {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Report updated successfully",
        },
      });
    });
    history.goBack();
  };

  const sharedProps = useShared({
    initShared: shared,
    initAssigned: [],
  });

  var { assignButton, assigness, sharedPeoples, privateButton } = SharedList(
    sharedProps,
    isPrivate,
    setPrivate
  );

  return (
    <StandardContainerWithCustomAppBar
      appBarContent={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <KeyboardBackspaceIcon style={{ size: 30, color: "#1976d2" }} />
          </span>
          <DuoButtonGroup
            primaryButtonText="Save"
            primaryButtonListener={() => saveAll()}
            hideSecondary={true}
            disableMargins={true}
          />
        </Box>
      }
    >
      <StandardAppContainerRounded sx={{ mx: "6%", p: 3 }}>
        <FormBox label="Quality and Safety Report">
          <Typography variant="subtitle1">
            Please fill up the following details
          </Typography>
        </FormBox>
        <SpaceBetween
          left={
            <FormBox label="Title">
              <TextField
                placeholder="Report Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
              />
            </FormBox>
          }
          right={
            <FormBox label="Description">
              <TextField
                placeholder="Report Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Project">
              <ProjectPickerDropdown
                fullWidth={true}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                hideLabel={true}
                defaultProjectId={defaultProjectId}
              />
            </FormBox>
          }
          right={
            <FormBox label="Organization">
              <OrgPickerDropdown
                fullWidth={true}
                selectedOrg={selectedOrganization}
                setSelectedOrg={setSelectedOrganization}
                hideLabel={true}
                defaultOrganizationId={defaultOrganizationId}
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Project Block">
              <ProjectBlockPickerDropdown
                fullWidth={true}
                selectedProjectBlock={selectedProjectBlock}
                setSelectedProjectBlock={setSelectedProjectBlock}
                hideLabel={true}
                projectId={selectedProject?._id}
              />
            </FormBox>
          }
          right={
            <FormBox label="Visit Date">
              <DatePicker
                value={visitDate}
                onChange={(date) => setVisitDate(date)}
              />
            </FormBox>
          }
        />
        <FormBox label="Observers">
          {observers?.map((item, index) => (
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography variant="h6">{index + 1}.</Typography>

              {expandedUserSearch[item?._id] ? (<>
                <PaginatedEntityDropdown
                  onChange={(entity) => {
                    updateObserver(index, {
                      profile: entity?.data,
                    });
                    handleExpandUser(item?._id, "close");
                  }}
                  isMulti={false}
                  entity={"User"}
                  curEntityId={user?.profile}
                  palCreate={false}
                  givenFilterOption={[
                  
                  {
                      option: "Network",
                      types: ["User"]
                  }
                  ]}
                />
              </>) : (
                <TextField
                  placeholder="User"
                  fullWidth
                  value={item?.profile?.parent?.displayName}
                  readonly
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => handleExpandUser(item?._id, "open")}
                      >
                        <IconButton color="primary" aria-label="add">
                          <ConnectWithoutContactOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <TextField
                placeholder="Designation"
                fullWidth
                value={item?.designation}
                onChange={(e) =>
                  updateObserver(index, { designation: e.target.value })
                }
              />
              <IconButton onClick={() => addObserver()}>
                <Add />
              </IconButton>

              <IconButton
                onClick={() => deleteObserver(index)}
                disabled={observers?.length <= 1}
              >
                <DeleteOutline />
              </IconButton>
            </Stack>
          ))}
        </FormBox>
        {/* <SpaceBetween
          left={
            <FormBox label="Logo">
              {logo ? (
                <DeletableImage
                  src={logo?.url}
                  onDelete={() => setLogo(null)}
                  width="auto"
                  height="80px"
                />
         
              ) : (
                <CustomFileUploadButton
                  showComponent={
                    <Box
                      sx={{
                        display: "flex",
                        width: "100px",
                        height: "79px",
                        padding: "16px 20px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "12px",
                        flexShrink: 0,
                        borderRadius: "10px",
                        border: "1px dashed #ABBED1",
                        background: "#F5F7FA",
                      }}
                    >
                      <img
                        src="/icons/app/document-upload.svg"
                        style={{ width: "28px", height: "28px" }}
                        alt="upload icon"
                      />
                    </Box>
                  }
                  parentType={"SiteReport"}
                  parentId={reportId}
                  fileNum={1}
                  givenMaxSize={26214400}
                  closeFunCall={() => {
                    if (createdFileIds?.length > 0) {
                      getAllFiles({ files: createdFileIds }).then((data) => {
                        setLogo(data[0]);
                      });
                    }

                    dispatch({ type: "FileUploadReset" });
                    dispatch({
                      type: "FileReset",
                    });
                  }}
                />
              )}
            </FormBox>
          }
        /> */}
      </StandardAppContainerRounded>
      <StandardAppContainerRounded sx={{ mx: "6%", p: 3 }}>
        {observations?.map((item, index) => (
          <Box
            fullWidth
            sx={{
              borderRadius: "10px",
              border: "1px solid rgba(0, 0, 0, 0.10)",
              p: 2,
              mb: 2,
            }}
            onClick={() => {
              setFilesObserver(item);
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormBox
                label={` Observation ${index + 1}`}
                labelTypographyVariant="subtitle1"
                disableMargins={true}
              />

              <Stack direction="row" spacing={1}>
                <IconButton
                  onClick={() => {
                    if (expanded[item?._id]) {
                      let temp = { ...expanded };
                      temp[item?._id] = false;
                      setExpanded(temp);
                    } else {
                      let temp = { ...expanded };
                      temp[item?._id] = true;
                      setExpanded(temp);
                    }
                  }}
                >
                  <KeyboardArrowDown
                    style={{
                      transform: expanded[item?._id]
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => addObservation()}>
                  <Add />
                </IconButton>
                <IconButton
                  onClick={() => deleteObservation(index)}
                  disabled={observations?.length <= 1}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            </Box>
            <Collapse in={expanded[item?._id]} timeout="auto" unmountOnExit>
              <FormBox label="Title">
                <TextField
                  placeholder="Enter Title"
                  fullWidth
                  value={item?.title}
                  onChange={(e) =>
                    updateObservation(index, { title: e.target.value })
                  }
                />
              </FormBox>
              <FormBox label="Description">
                {/* <TextField
                  multiline
                  fullWidth
                  rows={5}
                  inputProps={{ maxLength: 1000 }}
                  helperText={`${item?.description?.length || 0}/1000`}
                  value={item?.description}
                  onChange={(e) =>
                    updateObservation(index, { description: e.target.value })
                  }
                /> */}
                <SiteReportDoc
                  updateObservation={updateObservation}
                  description={item?.description}
                  index={index}
                />
              </FormBox>
              <FormBox label="Documents">
                <CustomFileUploadButton
                  showComponent={
                    <Box
                      sx={{
                        display: "flex",
                        height: "79px",
                        padding: "16px 20px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "12px",
                        flexShrink: 0,
                        borderRadius: "10px",
                        border: "1px dashed #ABBED1",
                        background: "#F5F7FA",
                      }}
                    >
                      <img
                        src="/icons/app/document-upload.svg"
                        style={{ width: "28px", height: "28px" }}
                        alt="upload icon"
                      />
                    </Box>
                  }
                  parentType={"Doc"}
                  parentId={null}
                  fileNum={25}
                  givenMaxSize={26214400}
                  closeFunCall={() => {
                    dispatch({ type: "FileUploadReset" });
                    dispatch({
                      type: "FileReset",
                    });
                  }}
                />
              </FormBox>

              <FilesViewer
                files={item?.files}
                isDeletable={true}
                deleteFunction={removeFun}
                disablePreview={true}
                disableShowMetaData={false}
                disableEditMetaData={false}
                onMetadataChange={(_id, key, value) => {
                  let temp = [...observations];
                  let index = temp.findIndex((o) => o?._id === item?._id);
                  if (index === -1) {
                    return;
                  }
                  let observation = temp[index];
                  let files = observation?.files || [];
                  let fileIndex = files.findIndex((item) => item?._id === _id);
                  if (fileIndex === -1) {
                    return;
                  }
                  let file = files[fileIndex];
                  let newObj = {};
                  newObj[key] = value;
                  file = { ...file, ...newObj };
                  files[fileIndex] = file;
                  observation.files = files;
                  temp[index] = observation;
                  let updateFileObj = { ...updatedFileIds };
                  updateFileObj[_id] = file;
                  setUpdatedFileIds(updateFileObj);
                  setObservations(temp);
                }}
              />
            </Collapse>
          </Box>
        ))}
      </StandardAppContainerRounded>
      {/* <Box>{assignButton}</Box>
      <Box>{assigness}</Box>
      <Box>{sharedPeoples}</Box>
      <Box>{privateButton}</Box>
      {sharedProps?.shareDrawer}
      {sharedProps?.assignedDialog} */}
    </StandardContainerWithCustomAppBar>
  );
};

export default EditSiteReport;
