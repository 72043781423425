import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainer from "../styled/generic/StandardAppContainer";
import FormBox from "../styled/generic/FormBox";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import StandardContainerWithCustomAppBar from "../styled/generic/StandardContainerWithCustomAppBar";
import Api from "../../helpers/Api";
import FilesViewer from "../styled/generic/FilesViewer";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import axios from "axios";
import configObject from "../../config";
import SpaceBetween from "../styled/generic/SpaceBetween";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ViewSiteReport = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedProjectBlock, setSelectedProjectBlock] = useState(null);
  const [defaultProjectId, setDefaultProjectId] = useState(null);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState(null);
  const [visitDate, setVisitDate] = useState();
  const [report, setReport] = useState(null);
  const [observers, setObservers] = useState([]);
  const [observations, setObservations] = useState([]);

  const { reportId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const componentRef = useRef();

  const getReport = async () => {
    try {
      const { data } = await Api.post("/site-report/get-by-id", {
        reportId: reportId,
      });
      setReport(data);
      setTitle(data?.title);
      setDescription(data?.description);
      setSelectedProject(data?.project?._id);
      setSelectedOrganization(data?.organization?._id);
      setDefaultProjectId(data?.project?._id);
      setDefaultOrganizationId(data?.organization?._id);
      setSelectedProjectBlock(data?.projectBlock?._id);
      setVisitDate(dayjs(data?.visitDate));
      setObservers(data?.observers || []);

      let orignalObservations = data?.observations || [];
      let processedObservations = [];

      for (let i = 0; i < orignalObservations.length; i++) {
        let curObservation = orignalObservations[i];
        let curObservationFiles = [];
        let curDocs = curObservation?.docs || [];
        for (let j = 0; j < curDocs.length; j++) {
          let curDoc = curDocs[j];
          let curDocFiles = curDoc?.files || [];
          for (let k = 0; k < curDocFiles.length; k++) {
            let curDocFile = curDocFiles[k];
            let processedFile = {
              ...curDocFile,
              title: curDoc?.title || "",
              description: curDoc?.description || "",
              tag: curDoc?.tag || "",
            };
            curObservationFiles = [...curObservationFiles, processedFile];
          }
        }
        processedObservations.push({
          ...curObservation,
          files: curObservationFiles,
        });
      }

      setObservations(processedObservations);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching report",
        },
      });
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const generatePDF = async () => {
    try {
      const response = await axios({
        url: `${configObject.apiUrl}/site-report/pdf`,
        method: "POST",
        responseType: "blob",
        data: {
          reportId: reportId,
        },
      });
      //quality and safety report
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `SiteReport-${report?.organization?.displayName}.pdf`
      );
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to generate PDF",
        },
      });
    }
  };

  const styles = {
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    report_container: {
      width: "60rem",
      display: "flex",
      flexDirection: "column",
      gap: "3rem",
    },
    report_header_cont: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",

      gap: "0.8rem",
      padding: "1rem 0rem",
      borderBottom: "2px solid gray",
    },
    report_header_img_cont: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
    head_img: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
    },
    fontMid: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    fontBig: {
      fontSize: "1.6rem",
      fontWeight: 500,
    },
    project_org_cont: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "1rem 0rem",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
    textName: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    textValue: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    sec: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    observation_container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      gap: "1.5rem",
    },
    obs_sec: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    obs_box: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    obs_ref_docs_cont: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    obs_img_sec: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(2, max-content)",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",
    },
    obs_img: {
      width: "50%",
      height: "180px",
    },
    obs_info: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },

    obs_doc_cont: {
      width: "30rem",

      display: "flex",
      alignItems: "center",

      gap: "0.7rem",
      padding: "1rem",
    },
    obs_text: {
      fontSize: "1.5rem",
      fontWeight: "bolder",
    },
    ref_text: {
      fontSize: "1.2rem",
      fontWeight: "bolder",
      paddingBottom: "0.4rem",
    },
    obs_img_info: {
      height: "180px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      gap: "1.5rem",
    },
  };

  console.log(report);

  return (
    <StandardContainerWithCustomAppBar
      appBarContent={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <KeyboardBackspaceIcon style={{ size: 30, color: "#1976d2" }} />
          </span>
          <DuoButtonGroup
            primaryButtonText="Export"
            primaryButtonListener={() => generatePDF(componentRef.current)}
            secondaryButtonText="Edit"
            secondaryButtonListener={() =>
              history.push(`/site-report/edit/${reportId}`)
            }
            disableMargins={true}
          />
        </Box>
      }
    >
      <div style={styles.root}>
        <div style={styles.report_container}>
          <div style={styles.report_header_cont}>
            <span style={styles.fontBig}>Site Visit Report</span>
            <div style={styles.report_header_img_cont}>
              <img
                style={styles.head_img}
                src={report?.organization?.displayPicture?.url}
                alt="o"
              />
              <span style={styles.fontMid}>
                {report?.organization?.displayName}
              </span>
            </div>
          </div>
          <div style={styles.project_org_cont}>
            <div style={styles.box}>
              <span style={styles.fontMid}>Project details</span>
              <div style={styles.sec}>
                <div>
                  <span style={styles.textName}>Project Name :</span>
                  <span style={styles.textValue}>
                    {" "}
                    {report?.project?.displayName || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}> Project Block :</span>
                  <span style={styles.textValue}>
                    {" "}
                    {report?.projectBlock?.name || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>Country : </span>
                  <span style={styles.textValue}>
                    {report?.project?.address?.country || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>State : </span>
                  <span style={styles.textValue}>
                    {report?.project?.address?.state || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>PinCode : </span>
                  <span style={styles.textValue}>
                    {report?.project?.address?.pinCode || "N/A"}
                  </span>
                </div>
              </div>
            </div>
            <div style={styles.box}>
              <span style={styles.fontMid}>Organization details</span>
              <div style={styles.sec}>
                <div>
                  <span style={styles.textName}>Name : </span>
                  <span style={styles.textValue}>
                    {report?.organization?.displayName || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>Country : </span>
                  <span style={styles.textValue}>
                    {report?.organization?.address?.country || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>State : </span>
                  <span style={styles.textValue}>
                    {report?.organization?.address?.state || "N/A"}
                  </span>
                </div>
                <div>
                  <span style={styles.textName}>PinCode : </span>
                  <span style={styles.textValue}>
                    {report?.organization?.address?.pinCode || "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.observation_container}>
            <span style={styles.obs_text}>Observations</span>
            <div style={styles.obs_sec}>
              {report?.observations?.map((obs) => (
                <div style={styles.obs_box}>
                  <div style={styles.obs_info}>
                    <div>
                      {/* <span style={styles.textName}>Title :</span> */}
                      <span style={styles.textName}> {obs?.title}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.4rem",
                      }}
                    >
                      {/* <span style={styles.textName}>Descreption :</span> */}
                      <div
                        style={styles.textValue}
                        dangerouslySetInnerHTML={{ __html: obs?.description }}
                      />
                    </div>
                  </div>
                  <div style={styles.obs_ref_docs_cont}>
                    <span style={styles.ref_text}>Reference Documents</span>
                    <div style={styles.obs_img_sec}>
                      {obs?.docs?.map((doc) => (
                        <div style={styles.obs_doc_cont}>
                          {doc?.files?.map(({ url }) => (
                            <img style={styles.obs_img} src={url} alt="" />
                          ))}
                          <div style={styles.obs_img_info}>
                            <span style={styles.textName}>{doc?.title}</span>
                            <span style={styles.textValue}>
                              {doc?.description}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </StandardContainerWithCustomAppBar>
  );
};

export default ViewSiteReport;
