import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory, Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Image from "./Image";

const useStyles = makeStyles((theme) => ({
  mainImgCont: {
    position: "relative",
    width: "100%",
    height: "calc(100% - 100px)",
    "& img": {
      float: "left",
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
    },
  },
  controlersCont: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  navGo: {
    width: "25%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0)",
    cursor: "pointer",
  },
  showFullPic: {
    width: "50%",
    height: "100%",
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  deleteCon: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px",
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  nextCon: {
    width: "100%",
    height: "calc(100% - 30px)",
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  fullImageCont: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  imageSty: {
    maxHeight: "80vh",
    maxWidth: "80vw",
  },
}));

const ImageContainer = ({ label, src, onDelete, isDeletable }) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "8px 11px",
        flexDirection: "column",
        alignItems: "flexStart",
        gap: "10px",
        borderRadius: "10px",
        border: "1px solid #F3F3F3",
        background: "#FFF",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          sx={{
            wordWrap: "break-word",
            whiteSpace: "pre-line",
            maxWidth: "200px",
          }}
        >
          {label}
        </Typography>
        {isDeletable && (
          <IconButton size="small" onClick={() => onDelete()}>
            <Close />
          </IconButton>
        )}
      </Box>
      <Box>
        <Image width="200px" aspectRatio="16:9" src={src} />
      </Box>
    </Box>
  );
};

const ImageContainerWithMetaData = ({
  label,
  src,
  onDelete,
  isDeletable,
  onMetadataChange,
  data,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #F3F3F3",
        background: "#F5F5F5",
      }}
    >
      <ImageContainer
        label={label}
        src={src}
        isDeletable={isDeletable}
        onDelete={onDelete}
      />
      <Box sx={{ ml: 2 }} flex="1">
        <Stack spacing={1}>
          <TextField
            size="small"
            placeholder="Title"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={data?.title || ""}
            onChange={(evt) =>
              onMetadataChange(data?._id, "title", evt.target.value)
            }
          />
          {/* <TextField
            size="small"
            placeholder="Tags"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={data?.tag || ""}
            onChange={(evt) =>
              onMetadataChange(data?._id, "tag", evt.target.value)
            }
          /> */}
          <TextField
            size="small"
            placeholder="Description"
            fullWidth
            multiline
            rows={3}
            sx={{ backgroundColor: "#fff" }}
            defaultValue={data?.description || ""}
            onChange={(evt) =>
              onMetadataChange(data?._id, "description", evt.target.value)
            }
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default function ImageVideoViewer(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const {
    data,
    onMetadataChange,
    disablePreview,
    isDeletable,
    removeFun,
    disableEditMetaData,
    disableShowMetaData,
  } = props;

  const videoThum = "https://placekitten.com/450/300";

  const [current, setCurrent] = useState(0);
  const [clickedImg, setClickedImg] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    setClickedImg(null);
    setDialogOpen(false);
  };

  const next = () => {
    if (data.length > 1) {
      if (current === data.length - 1) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    }
  };

  const previous = () => {
    if (data.length > 1) {
      if (current === 0) {
        setCurrent(data.length - 1);
      } else {
        setCurrent(current - 1);
      }
    }
  };

  return (
    <Box>
      {!disablePreview && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <div>
            <div onClick={previous}></div>
            <div
              onClick={() => {
                if (data[current]?.type === "photo") {
                  setClickedImg(data[current]?.url);
                  setDialogOpen(true);
                }
              }}
            ></div>
            <div>
              <div>
                <div></div>
                {isDeletable && (
                  <CancelIcon
                    style={{ color: "gray", fontSize: "30px" }}
                    onClick={() => {
                      removeFun(data[current]?._id);
                    }}
                  />
                )}
              </div>
              <div onClick={next}></div>
            </div>
          </div>

          {data[current]?.type === "video" && data[current]?.url ? (
            <video width="100%" height="100%" controls>
              <source src={data[current]?.url} type="video/mp4" />
            </video>
          ) : (
            <img src={data[current]?.url} alt="main" />
          )}
        </Box>
      )}

      <Grid container spacing={2}>
        {data?.map((iv, i) => (
          <Grid
            item
            xs={disableShowMetaData ? "auto" : 6}
            key={i}
            onClick={() => {
              setCurrent(i);
            }}
          >
            {iv?.type === "photo" ? (
              <>
                {disableShowMetaData ? (
                  <ImageContainer
                    label={iv?.description || iv?.title || "Untitled"}
                    src={iv?.url}
                    isDeletable={isDeletable}
                    onDelete={removeFun}
                  />
                ) : (
                  <ImageContainerWithMetaData
                    data={iv}
                    label={iv?.title || iv?.description || "Untitled"}
                    src={iv?.url}
                    isDeletable={isDeletable}
                    onDelete={removeFun}
                    curData={iv}
                    onMetadataChange={onMetadataChange}
                  />
                )}
              </>
            ) : (
              <img src={videoThum} alt="nav" />
            )}
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "5px",
            maxWidth: "1200px",
          },
        }}
      >
        <DialogContent
          className={classes.fullImageCont}
          style={{ padding: "0" }}
        >
          {clickedImg && <img className={classes.imageSty} src={clickedImg} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
