import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import StandardContainer from "./StandardContainer";
import AppBar from "./AppBar";

const APP_BAR_HEIGHT = "60px";

const StandardContainerWithCustomAppBar = ({
  children,
  containerStyleProps,
  appBarContent,
  ...props
}) => {
  return (
    <Box width="100%" sx={{ position: "relative" }} {...props}>
      <AppBar
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: APP_BAR_HEIGHT,
        }}
      >
        {appBarContent}
      </AppBar>
      <StandardContainer sx={{ ...containerStyleProps }}>
        <Box sx={{ mt: APP_BAR_HEIGHT }}> {children}</Box>
      </StandardContainer>
    </Box>
  );
};

export default StandardContainerWithCustomAppBar;
